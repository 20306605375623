<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="拦截单号">
              <a-input v-model="queryParam.intercept_order_no" placeholder="请输入拦截单号" @keyup.enter.native="$refs.table.refresh(true)" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="拣货单号">
              <a-input v-model="queryParam.pick_list_no" placeholder="请输入拣货单号" @keyup.enter.native="$refs.table.refresh(true)" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24" v-if="false">
            <a-form-item label="拣货单">
              <a-select
                allow-clear
                v-model="queryParam.pick_list_id"
                option-filter-prop="children"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                show-search
                placeholder="拣货单"
                @search="handleWmsPicksListSearch"
                @change="handleWmsPicksListChange"
              >
                <a-select-option v-for="d in dataSource_wms_picks_list" :key="d.id" :value="d.id">
                  {{ d.pick_list_no }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="searchFilter">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator" v-if="false">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <a-modal v-model="select_status_visible" title="请选择状态" ok-text="确认" cancel-text="取消" @ok="select_status">
      <a-select style="width: 280px" @change="handleSelectChange" v-model="select_status_data">
        <a-select-option
          v-for=" (val, key) in this.$Dictionaries.intercept_order_status"
          :key="key"
          :value="key">
          {{ val }}
        </a-select-option>
      </a-select>
    </a-modal>
    <a-modal v-model="visible" title="创建" ok-text="确认" cancel-text="取消" @ok="handleOk">
      拣货单号：
      <a-select
        style="width: 320px"
        @change="handleSelectChange"
        v-model="select_pick_list_id"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="fetching ? undefined : null"
        show-search
        allow-clear
        @search="handleWmsPicksListSearch">
        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
        <a-select-option
          v-for=" (val, key) in this.dataSource_wms_picks_list"
          :key="key"
          :value="val.id">
          {{ val.pick_list_no }}
        </a-select-option>
      </a-select>
    </a-modal>

  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import {
  intercept_order_create,
  intercept_order_delete,
  intercept_order_list,
  intercept_order_update
} from '@/api/c_wms_intercept_order'
import { picks_list } from '@/api/c_wms_picks'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      select_status_visible: false,
      select_edit_record: {},
      select_status_data: '',
      select_pick_list_id: '',
      mdl: {},
      fetching: false,
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      dataSource_wms_picks_list: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '拦截单号',
          ellipsis: true,
          width: 200,
          dataIndex: 'intercept_order_no'
        },
        {
          title: '拣货单号',
          ellipsis: true,
          width: 200,
          dataIndex: 'pick_list_no'
        },
        {
          width: 100,
          title: '创建人',
          dataIndex: 'create_by',
          ellipsis: true
        },
        {
          width: 100,
          title: '操作人',
          dataIndex: 'operate_by',
          ellipsis: true
        },
        {
          width: 100,
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.intercept_order_status[text] || '无'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return intercept_order_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    // this.handleWmsPicksListSearch(undefined)
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    searchFilter () {
      this.$refs.table.refresh(true)
      this.dataSource_wms_picks_list = []
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.select_status_visible = true
      this.select_edit_record = record
      console.log('修改状态')
      this.select_status_data = '' + this.select_edit_record.status
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    select_status () {
      const param = {
        status: Number(this.select_status_data)
      }
      intercept_order_update(param, this.select_edit_record.id).then((res) => {
        this.select_status_visible = false
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleInCancel () {
      this.purchase_form_visible = false
      const form = this.$refs.createCommodityPurchaseModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      intercept_order_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      this.confirmLoading = true
      if (this.select_pick_list_id) {
        // 新增
        const values = { pick_list_id: this.select_pick_list_id }
        intercept_order_create(values).then(res => {
          this.visible = false
          this.confirmLoading = false
          this.select_pick_list_id = ''
          this.dataSource_wms_picks_list = []
          // 刷新表格
          this.$refs.table.refresh()
        }).catch((err) => {
            console.log(err)
            this.confirmLoading = false
          }).finally(() => {
            this.dataSource_wms_picks_list = []
        })
      } else {
        this.confirmLoading = false
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    filterOption (input, option) {
      console.log(input, option.componentOptions.children[0].text, this.dataSource_wms_picks_list.length + '-------------------------')
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleWmsPicksListSearch (value) {
      this.fetching = true
      picks_list({ pick_list_no: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_picks_list = result.filter((item) => {
          return item.pick_list_no.length > 0
        })
        this.fetching = false
        console.log('---' + result.length, this.dataSource_wms_picks_list.length)
      })
    },
    handleWmsPicksListChange (value) {
      const objneo = this.dataSource_wms_picks_list.find((item) => {
        return item.pick_list_no === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_picks_list)
      var obj = Object.assign({}, objneo)
      console.log(obj)
    }
  }
}
</script>
